/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Throttles a function with window.requestAnimationFrame()
 *
 * Useful for event handlers that may fire very rapidly (e.g. resize, scroll, etc)
 */
function throttleRaf(fn, win=window) {
  let isRunning, self, args;

  function run() {
    isRunning = false;
    fn.apply(self, args);
  }

  return function throttledFn() {
    self = this;
    args = Array.prototype.slice.call(arguments);

    if (isRunning) {
      return;
    }

    isRunning = true;
    win.requestAnimationFrame(run);
  };
}

module.exports = throttleRaf;
