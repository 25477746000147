// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "19.7.0",
  "buildNumber": "9567",
  "gitSha": "727ea9db824eb6971170ac2a886ff1072ff5a235",
  "fullVersion": "19.7.0-9567",
  "formattedVersion": "19.7.0 (9567-727ea9db824eb6971170ac2a886ff1072ff5a235)",
  "copyrightYear": "2019"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/19.7.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/19.7.0/${stripLeadingPrefix(suffix, '#')}`
}
    
