// This file is automatically generated by `generated_js` task. Any changes will be lost
/*
File generated by js-routes 1.4.4
Based on Rails routes of Go::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  function ParameterMissing(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber);
    if(Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  }

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf){
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "/go",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin_cluster_status_report => /admin/status_reports/:plugin_id/cluster/:cluster_profile_id
  // function(plugin_id, cluster_profile_id, options)
  adminClusterStatusReportPath: Utils.route([["plugin_id",true],["cluster_profile_id",true]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"status_reports",false],[2,[7,"/",false],[2,[3,"plugin_id",false],[2,[7,"/",false],[2,[6,"cluster",false],[2,[7,"/",false],[3,"cluster_profile_id",false]]]]]]]]]]),
// admin_status_report => /admin/status_reports/:plugin_id
  // function(plugin_id, options)
  adminStatusReportPath: Utils.route([["plugin_id",true]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"status_reports",false],[2,[7,"/",false],[3,"plugin_id",false]]]]]]),
// analytics => /analytics(.:format)
  // function(options)
  analyticsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"analytics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// apiv1 => /api/*url
  // function(url, options)
  apiv1Path: Utils.route([["url",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[5,[3,"*url",false],false]]]]),
// apiv1_admin_internal_command_snippets => /api/admin/internal/command_snippets
  // function(options)
  apiv1AdminInternalCommandSnippetsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"command_snippets",false]]]]]]]]),
// apiv1_admin_internal_environments => /api/admin/internal/environments
  // function(options)
  apiv1AdminInternalEnvironmentsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"environments",false]]]]]]]]),
// apiv1_admin_internal_material_test => /api/admin/internal/material_test
  // function(options)
  apiv1AdminInternalMaterialTestPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"material_test",false]]]]]]]]),
// apiv1_admin_internal_package_check_connection => /api/admin/internal/package_check_connection
  // function(options)
  apiv1AdminInternalPackageCheckConnectionPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"package_check_connection",false]]]]]]]]),
// apiv1_admin_internal_pipelines => /api/admin/internal/pipelines
  // function(options)
  apiv1AdminInternalPipelinesPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"pipelines",false]]]]]]]]),
// apiv1_admin_internal_repository_check_connection => /api/admin/internal/repository_check_connection
  // function(options)
  apiv1AdminInternalRepositoryCheckConnectionPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"repository_check_connection",false]]]]]]]]),
// apiv1_admin_internal_resources => /api/admin/internal/resources
  // function(options)
  apiv1AdminInternalResourcesPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[6,"resources",false]]]]]]]]),
// apiv1_admin_internal_verify_connection => /api/admin/internal/security/auth_configs/verify_connection
  // function(options)
  apiv1AdminInternalVerifyConnectionPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"security",false],[2,[7,"/",false],[2,[6,"auth_configs",false],[2,[7,"/",false],[6,"verify_connection",false]]]]]]]]]]]]),
// apiv1_admin_merged_environment_index => /api/admin/environments/merged
  // function(options)
  apiv1AdminMergedEnvironmentIndexPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[6,"merged",false]]]]]]]]),
// apiv1_admin_merged_environment_show => /api/admin/environments/:environment_name/merged
  // function(environment_name, options)
  apiv1AdminMergedEnvironmentShowPath: Utils.route([["environment_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[3,"environment_name",false],[2,[7,"/",false],[6,"merged",false]]]]]]]]]]),
// apiv1_admin_package => /api/admin/packages/:package_id
  // function(package_id, options)
  apiv1AdminPackagePath: Utils.route([["package_id",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"packages",false],[2,[7,"/",false],[3,"package_id",false]]]]]]]]),
// apiv1_admin_packages => /api/admin/packages
  // function(options)
  apiv1AdminPackagesPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"packages",false]]]]]]),
// apiv1_admin_plugin_setting => /api/admin/plugin_settings/:plugin_id
  // function(plugin_id, options)
  apiv1AdminPluginSettingPath: Utils.route([["plugin_id",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"plugin_settings",false],[2,[7,"/",false],[3,"plugin_id",false]]]]]]]]),
// apiv1_admin_plugin_settings => /api/admin/plugin_settings
  // function(options)
  apiv1AdminPluginSettingsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"plugin_settings",false]]]]]]),
// apiv1_admin_repositories => /api/admin/repositories
  // function(options)
  apiv1AdminRepositoriesPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"repositories",false]]]]]]),
// apiv1_admin_repository => /api/admin/repositories/:repo_id
  // function(repo_id, options)
  apiv1AdminRepositoryPath: Utils.route([["repo_id",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"repositories",false],[2,[7,"/",false],[3,"repo_id",false]]]]]]]]),
// apiv1_admin_scm => /api/admin/scms/:material_name
  // function(material_name, options)
  apiv1AdminScmPath: Utils.route([["material_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"scms",false],[2,[7,"/",false],[3,"material_name",false]]]]]]]]),
// apiv1_admin_scms => /api/admin/scms
  // function(options)
  apiv1AdminScmsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"scms",false]]]]]]),
// apiv1_admin_security_auth_config => /api/admin/security/auth_configs/:auth_config_id
  // function(auth_config_id, options)
  apiv1AdminSecurityAuthConfigPath: Utils.route([["auth_config_id",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"security",false],[2,[7,"/",false],[2,[6,"auth_configs",false],[2,[7,"/",false],[3,"auth_config_id",false]]]]]]]]]]),
// apiv1_admin_security_auth_configs => /api/admin/security/auth_configs
  // function(options)
  apiv1AdminSecurityAuthConfigsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"security",false],[2,[7,"/",false],[6,"auth_configs",false]]]]]]]]),
// apiv1_admin_security_role => /api/admin/security/roles/:role_name
  // function(role_name, options)
  apiv1AdminSecurityRolePath: Utils.route([["role_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"security",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[3,"role_name",false]]]]]]]]]]),
// apiv1_admin_security_roles => /api/admin/security/roles
  // function(options)
  apiv1AdminSecurityRolesPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"security",false],[2,[7,"/",false],[6,"roles",false]]]]]]]]),
// apiv1_admin_template => /api/admin/templates/:template_name
  // function(template_name, options)
  apiv1AdminTemplatePath: Utils.route([["template_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[3,"template_name",false]]]]]]]]),
// apiv1_admin_templates => /api/admin/templates/:template_name/authorization
  // function(template_name, options)
  apiv1AdminTemplatesPath: Utils.route([["template_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_name",false],[2,[7,"/",false],[6,"authorization",false]]]]]]]]]]),
// apiv1_current_user => /api/current_user
  // function(options)
  apiv1CurrentUserPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[6,"current_user",false]]]]),
// apiv1_latest_version_info => /api/version_infos/latest_version
  // function(options)
  apiv1LatestVersionInfoPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"version_infos",false],[2,[7,"/",false],[6,"latest_version",false]]]]]]),
// apiv1_notification_filter => /api/notification_filters/:id
  // function(id, options)
  apiv1NotificationFilterPath: Utils.route([["id",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"notification_filters",false],[2,[7,"/",false],[3,"id",false]]]]]]),
// apiv1_notification_filters => /api/notification_filters
  // function(options)
  apiv1NotificationFiltersPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[6,"notification_filters",false]]]]),
// apiv1_stale_version_info => /api/version_infos/stale
  // function(options)
  apiv1StaleVersionInfoPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"version_infos",false],[2,[7,"/",false],[6,"stale",false]]]]]]),
// apiv1_update_server_version_info => /api/version_infos/go_server
  // function(options)
  apiv1UpdateServerVersionInfoPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"version_infos",false],[2,[7,"/",false],[6,"go_server",false]]]]]]),
// apiv1_user => /api/users/:login_name
  // function(login_name, options)
  apiv1UserPath: Utils.route([["login_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[3,"login_name",false]]]]]]),
// apiv1_users => /api/users
  // function(options)
  apiv1UsersPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[6,"users",false]]]]),
// apiv2 => /api/users/:login_name
  // function(login_name, options)
  apiv2Path: Utils.route([["login_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[3,"login_name",false]]]]]]),
// apiv2_user => /api/users/:login_name
  // function(login_name, options)
  apiv2UserPath: Utils.route([["login_name",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[3,"login_name",false]]]]]]),
// apiv2_users => /api/users
  // function(options)
  apiv2UsersPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[6,"users",false]]]]),
// environment_create => /admin/environments/create(.:format)
  // function(options)
  environmentCreatePath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// environment_edit_agents => /admin/environments/:name/edit/agents(.:format)
  // function(name, options)
  environmentEditAgentsPath: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"agents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// environment_edit_pipelines => /admin/environments/:name/edit/pipelines(.:format)
  // function(name, options)
  environmentEditPipelinesPath: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"pipelines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// environment_edit_variables => /admin/environments/:name/edit/variables(.:format)
  // function(name, options)
  environmentEditVariablesPath: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"variables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// environment_new => /admin/environments/new(.:format)
  // function(options)
  environmentNewPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// environment_redirect => /environments(.:format)
  // function(options)
  environmentRedirectPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"environments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// environment_show => /admin/environments/:name/show(.:format)
  // function(name, options)
  environmentShowPath: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// environment_update => /admin/environments/:name(.:format)
  // function(name, options)
  environmentUpdatePath: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// environments => /admin/environments(.:format)
  // function(options)
  environmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"environments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// internal_stage_detail_tab => /internal/pipelines/:pipeline_name/:pipeline_counter(.:format)
  // function(pipeline_name, pipeline_counter, options)
  internalStageDetailTabPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pipeline_edit => /admin/:stage_parent/:pipeline_name/:current_tab(.:format)
  // function(stage_parent, pipeline_name, current_tab, options)
  pipelineEditPath: Utils.route([["stage_parent",true],["pipeline_name",true],["current_tab",true],["format",false]], {"stage_parent":"pipelines"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[3,"stage_parent",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"current_tab",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pipeline_group_config_list_api => /api/config/pipeline_groups(.:format)
  // function(options)
  pipelineGroupConfigListApiPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"config",false],[2,[7,"/",false],[2,[6,"pipeline_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pipeline_group_create => /admin/pipeline_group(.:format)
  // function(options)
  pipelineGroupCreatePath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pipeline_group_delete => /admin/pipeline_group/:group_name(.:format)
  // function(group_name, options)
  pipelineGroupDeletePath: Utils.route([["group_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline_group",false],[2,[7,"/",false],[2,[3,"group_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pipeline_group_edit => /admin/pipeline_group/:group_name/edit(.:format)
  // function(group_name, options)
  pipelineGroupEditPath: Utils.route([["group_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline_group",false],[2,[7,"/",false],[2,[3,"group_name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pipeline_group_new => /admin/pipeline_group/new(.:format)
  // function(options)
  pipelineGroupNewPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline_group",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pipeline_group_show => /admin/pipeline_group/:group_name(.:format)
  // function(group_name, options)
  pipelineGroupShowPath: Utils.route([["group_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline_group",false],[2,[7,"/",false],[2,[3,"group_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pipeline_group_update => /admin/pipeline_group/:group_name(.:format)
  // function(group_name, options)
  pipelineGroupUpdatePath: Utils.route([["group_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline_group",false],[2,[7,"/",false],[2,[3,"group_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pipeline_groups => /admin/pipelines(.:format)
  // function(options)
  pipelineGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipelines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pipeline_new => /admin/pipeline/new(.:format)
  // function(options)
  pipelineNewPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"pipeline",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// show_analytics => /analytics/:plugin_id/:type/:id(.:format)
  // function(plugin_id, type, id, options)
  showAnalyticsPath: Utils.route([["plugin_id",true],["type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[3,"plugin_id",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// stage_detail_tab_default => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabDefaultPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// stage_detail_tab_jobs => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/jobs(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabJobsPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// stage_detail_tab_materials => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/materials(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabMaterialsPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[2,[7,"/",false],[2,[6,"materials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// stage_detail_tab_overview => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/overview(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabOverviewPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// stage_detail_tab_pipeline => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/pipeline(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabPipelinePath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[2,[7,"/",false],[2,[6,"pipeline",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// stage_detail_tab_stage_config => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stage_config(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabStageConfigPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[2,[7,"/",false],[2,[6,"stage_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// stage_detail_tab_stats => /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stats(.:format)
  // function(pipeline_name, pipeline_counter, stage_name, stage_counter, options)
  stageDetailTabStatsPath: Utils.route([["pipeline_name",true],["pipeline_counter",true],["stage_name",true],["stage_counter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pipelines",false],[2,[7,"/",false],[2,[3,"pipeline_name",false],[2,[7,"/",false],[2,[3,"pipeline_counter",false],[2,[7,"/",false],[2,[3,"stage_name",false],[2,[7,"/",false],[2,[3,"stage_counter",false],[2,[7,"/",false],[2,[6,"stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    Utils.make();
  }

}).call(this);
