/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const m = require('mithril');
const f = require('helpers/form_helper');

const HeaderPanel = function () {

  function oninit() {
  }

  function view(vnode) {
    let button;
    if (vnode.attrs.button) {
      button = (
        <f.column size={6}>
          {vnode.attrs.button}
        </f.column>
      );
    }
    return (
      <div class="header-panel">
        <header class="page-header">
          <f.row>
            <f.column size={6}>
              <h1>{vnode.attrs.title}</h1>
            </f.column>
            {button}
          </f.row>
        </header>
      </div>
    );
  }

  return {oninit, view};

}();

module.exports = HeaderPanel;
